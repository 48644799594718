import { FC } from "react";
import { useI18nMessage } from "@lookiero/i18n-react";
import HistoryI18n, { HISTORY_I18N_PREFIX } from "../../../../componentLibrary/_i18n/HistoryI18n";
import Tabs from "../../../../../shared/ui/uiKit/layouts/tabs/Tabs";
import { HistoryBoxes } from "../../../../componentLibrary/organisms/history/historyBoxes/HistoryBoxes";
import { HistoryShop } from "../../../../componentLibrary/organisms/history/historyShop/HistoryShop";
import { StyleProfileCard } from "../../../../componentLibrary/organisms/styleProfileCard/StyleProfileCard";
import { StyleProfileCardTitle } from "../../../../componentLibrary/organisms/styleProfileCard/components/Title";
import RootLoader from "../../../../componentLibrary/atoms/rootLoader/RootLoader";
import classNames from "classnames";
import { useViewPersonalShopper } from "../../../../../core/infrastructure/projection/personalShopper/react/useViewPersonalShopper";
import { useSearchHistoryBoxesByCriteria } from "../../../../../core/infrastructure/projection/historyBox/react/useSearchHistoryBoxesByCriteria";
import { useSearchHistoryShopByCustomerId } from "../../../../../core/infrastructure/projection/historyShop/react/useSearchHistoryShopByCustomerId";
import "./history.css";

interface HistoryProps {
  readonly customerId: string;
  readonly className?: string;
  readonly onClickProductVariant: (productVariantId: string) => void;
}
const History: FC<HistoryProps> = ({ className, customerId, onClickProductVariant }) => {
  const [historyShop] = useSearchHistoryShopByCustomerId({ customerId });
  const [historyBoxes] = useSearchHistoryBoxesByCriteria({ customerId });
  const [personalShopper] = useViewPersonalShopper();

  const historyTitle = useI18nMessage({
    id: HistoryI18n.HISTORY_TITLE,
    prefix: HISTORY_I18N_PREFIX,
  });
  const historyBoxesLabel = useI18nMessage({ id: HistoryI18n.HISTORY_FILTER_BOXES, prefix: HISTORY_I18N_PREFIX });
  const historyShopLabel = useI18nMessage({ id: HistoryI18n.HISTORY_FILTER_SHOP, prefix: HISTORY_I18N_PREFIX });

  if (!historyBoxes || !personalShopper || !historyShop) {
    return <RootLoader />;
  }

  return historyBoxes.length > 0 || historyShop.length > 0 ? (
    <StyleProfileCard className={classNames("history-card", className)} label="history">
      <StyleProfileCardTitle>{historyTitle}</StyleProfileCardTitle>

      <Tabs labels={[`${historyBoxesLabel} ${historyBoxes.length}`, `${historyShopLabel} ${historyShop.length}`]}>
        <HistoryBoxes
          customerId={customerId}
          historyBoxes={historyBoxes}
          personalShopper={personalShopper}
          onClickProductVariant={onClickProductVariant}
        />
        <HistoryShop
          customerId={customerId}
          historyShop={historyShop}
          personalShopper={personalShopper}
          onClickProductVariant={onClickProductVariant}
        />
      </Tabs>
    </StyleProfileCard>
  ) : null;
};

export { History };
