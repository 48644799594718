import { FC } from "react";
import { Feedback } from "./Feedback";
import { HistoryProductVariantFeedbackProjection } from "../../../core/projection/historyBox/historyBox";

interface FeedbacksProps {
  readonly feedbacks: HistoryProductVariantFeedbackProjection[];
}

const Feedbacks: FC<FeedbacksProps> = ({ feedbacks }) => (
  <>
    {feedbacks.map((feedback) => (
      <Feedback key={feedback.question.id} feedback={feedback} />
    ))}
  </>
);

export { Feedbacks };
