import { FeedbackType } from "../../../../../core/projection/historyBox/historyBox.constants";
import Text, { TextVariant } from "../../../../../shared/ui/uiKit/atoms/text/Text";
import { FeedbackItem } from "../FeedbackItem";
import "./feedback-textarea-item.css";

const FeedbackTextareaItem: FeedbackItem<FeedbackType.TEXTAREA> = ({ answer }) => (
  <Text className="feedback-textarea-item" tag="p" variant={TextVariant.BODY_SMALL}>
    <strong>{answer.comment}</strong>
  </Text>
);

export { FeedbackTextareaItem };
