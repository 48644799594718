enum FeedbackType {
  HOST_DEFAULT = "HOST_DEFAULT",
  HOST_TEXTAREA = "HOST_TEXTAREA",
  HOST_SELECT = "HOST_SELECT",
  HOST_STACK = "HOST_STACK",
  TEXTAREA = "TEXTAREA",
  OPTION = "OPTION",
}

type FeedbackIconPrefix = "return_question.icon";

type FeedbackIcon =
  | `${FeedbackIconPrefix}.filled_heart`
  | `${FeedbackIconPrefix}.crossed_heart`
  | `${FeedbackIconPrefix}.quality`
  | `${FeedbackIconPrefix}.price`
  | `${FeedbackIconPrefix}.cut`
  | `${FeedbackIconPrefix}.others`
  | `${FeedbackIconPrefix}.size_small`
  | `${FeedbackIconPrefix}.size_big`
  | `${FeedbackIconPrefix}.size_correct`
  | `${FeedbackIconPrefix}.shoes_comfortable`
  | `${FeedbackIconPrefix}.shoes_uncomfortable`
  | `${FeedbackIconPrefix}.comment`;

type FeedbackSection = "strip" | "bottom";

export { FeedbackType };
export type { FeedbackIcon, FeedbackSection };
