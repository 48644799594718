import { FC, memo } from "react";
import { useFeedbackItem } from "./behaviors/useFeedbackItem";
import { HistoryProductVariantFeedbackProjection } from "../../../core/projection/historyBox/historyBox";

interface FeedbackProps {
  readonly feedback: HistoryProductVariantFeedbackProjection;
}

const Feedback: FC<FeedbackProps> = ({ feedback }) => {
  const Item = useFeedbackItem({ type: feedback.answer.type });

  return <Item answer={feedback.answer} question={feedback.question} />;
};

const MemorizedFeedback = memo(Feedback);

export { MemorizedFeedback as Feedback };
