import { useI18nMessage } from "@lookiero/i18n-react";
import { useIntl } from "react-intl";
import Text, { TextVariant } from "../../../../../shared/ui/uiKit/atoms/text/Text";
import { FeedbackItem } from "../FeedbackItem";
import { FeedbackType } from "../../../../../core/projection/historyBox/historyBox.constants";
import "./feedback-text-item.css";

const FeedbackTextItem: FeedbackItem<FeedbackType.OPTION> = ({ question, answer }) => {
  const { formatMessage } = useIntl();
  const titleText = useI18nMessage({ id: question.translationKey });

  const feedback = [
    formatMessage({ id: answer.translationKey }),
    answer.child ? formatMessage({ id: answer.child.translationKey }) : "",
  ].join(answer.child ? " / " : "");

  return (
    <Text className="feedback-text-item" tag="p" variant={TextVariant.BODY_SMALL}>
      {titleText}: <strong>{feedback}</strong>
    </Text>
  );
};

export { FeedbackTextItem };
