import { FC } from "react";
import {
  FeedbackItemProvider,
  FeedbackItems,
} from "../../../../views/historyReturnQuestions/behaviors/useFeedbackItem";
import { HostDefaultFeedbackItem } from "../../../../views/historyReturnQuestions/components/hostDefaultFeedbackItem/HostDefaultFeedbackItem";
import { FeedbackTextItem } from "../../../../views/historyReturnQuestions/components/feedbackTextItem/FeedbackTextItem";
import { Feedbacks } from "../../../../views/historyReturnQuestions/Feedbacks";
import { HistoryProductVariantFeedbackProjection } from "../../../../../core/projection/historyBox/historyBox";
import { FeedbackTextareaItem } from "../../../../views/historyReturnQuestions/components/feedbackTextareaItem/FeedbackTextareaItem";
import { FeedbackType } from "../../../../../core/projection/historyBox/historyBox.constants";

const feedbackItems: FeedbackItems = {
  [FeedbackType.HOST_DEFAULT]: HostDefaultFeedbackItem,
  [FeedbackType.HOST_TEXTAREA]: HostDefaultFeedbackItem,
  [FeedbackType.HOST_SELECT]: HostDefaultFeedbackItem,
  [FeedbackType.HOST_STACK]: HostDefaultFeedbackItem,
  [FeedbackType.TEXTAREA]: FeedbackTextareaItem,
  [FeedbackType.OPTION]: FeedbackTextItem,
};

interface HistoryProductVariantFeedbackTooltipProps {
  readonly feedbacks: HistoryProductVariantFeedbackProjection[];
}

const HistoryProductVariantFeedbackTooltip: FC<HistoryProductVariantFeedbackTooltipProps> = ({ feedbacks }) => (
  <FeedbackItemProvider feedbackItems={feedbackItems}>
    <Feedbacks feedbacks={feedbacks} />
  </FeedbackItemProvider>
);

export { HistoryProductVariantFeedbackTooltip };
