import { FC, useCallback } from "react";
import { I18nDate, I18nMessage, useI18nMessage } from "@lookiero/i18n-react";
import { HistoryProductVariant } from "../historyProductVariant/HistoryProductVariant";
import Card from "../../../../shared/ui/uiKit/layouts/card/Card";
import Text, { TextVariant } from "../../../../shared/ui/uiKit/atoms/text/Text";
import Price from "../../atoms/price/Price";
import AssignedPs from "../../molecules/assignedPs/AssignedPs";
import HistoryI18n, { HISTORY_I18N_PREFIX } from "../../_i18n/HistoryI18n";
import HistoryBoxPreviewButton from "./historyBoxPreviewButton/HistoryBoxPreviewButton";
import Tooltip from "../../../../shared/ui/uiKit/atoms/tooltip/Tooltip";
import { CUSTOMER_I18N_PREFIX, CustomerI18n } from "../../_i18n/CustomerI18n";
import {
  HistoryBoxProjection,
  HistoryProductVariantProjection,
} from "../../../../core/projection/historyBox/historyBox";
import { HistoryShopProductVariantProjection } from "../../../../core/projection/historyShop/historyShopProductVariant";
import "./history-box.css";

interface OnViewProductVariantWithProductInfoFunctionArgs {
  readonly productId: string;
  readonly productVariantId: string;
}
interface OnViewProductVariantWithProductInfoFunction {
  (args: OnViewProductVariantWithProductInfoFunctionArgs): void;
}

interface HistoryBoxItemProps {
  readonly item: HistoryProductVariantProjection | HistoryShopProductVariantProjection;
  readonly onClickProductVariant?: OnViewProductVariantWithProductInfoFunction;
}

const HistoryBoxItem: FC<HistoryBoxItemProps> = ({ item, onClickProductVariant }) => {
  const handleOnClickProductVariant = useCallback(
    () => onClickProductVariant?.({ productId: item.productId, productVariantId: item.productVariantId }),
    [item.productId, item.productVariantId, onClickProductVariant],
  );

  return <HistoryProductVariant productVariant={item} onClick={handleOnClickProductVariant} />;
};

export enum HistoryBoxCommentType {
  ORDER = "ORDER",
  CHECKOUT = "CHECKOUT",
}

interface HistoryBoxProps {
  readonly box: HistoryBoxProjection;
  readonly onClickProductVariant: OnViewProductVariantWithProductInfoFunction;
  readonly onClickBoxPreview: (boxId: string) => void;
}

const HistoryBox: FC<HistoryBoxProps> = ({ box, onClickProductVariant, onClickBoxPreview }) => {
  const orderCommentLabel = useI18nMessage({ id: CustomerI18n.COMMENT_GENERAL_PREVIOUS, prefix: CUSTOMER_I18N_PREFIX });
  const checkoutCommentLabel = useI18nMessage({
    id: CustomerI18n.COMMENT_CHECKOUT,
    prefix: CUSTOMER_I18N_PREFIX,
  });
  const customerServiceCommentLabel = useI18nMessage({
    id: CustomerI18n.COMMENT_CUSTOMER_SERVICE,
    prefix: CUSTOMER_I18N_PREFIX,
  });

  const handleOnClickBoxPreview = useCallback(() => onClickBoxPreview(box.id), [box.id, onClickBoxPreview]);

  return (
    <article aria-label="history-box" className="history-box" role="listitem">
      <Card className="history-box__card">
        {box.items.map((item) => (
          <HistoryBoxItem key={item.productVariantId} item={item} onClickProductVariant={onClickProductVariant} />
        ))}

        <section className="history-box__info">
          <div className="history-box__header">
            <div className="history-box__pricing">
              <Price className="history-box__pricing-purchased" price={box.pricing.purchased} />
              <Text className="history-box__pricing-separator">|</Text>
              <Price className="history-box__pricing-total" price={box.pricing.total} />
            </div>

            <div className="history-box__row">
              {box.preview && <HistoryBoxPreviewButton status={box.preview.status} onClick={handleOnClickBoxPreview} />}
              {box.assignedPs && <AssignedPs legacyId={box.assignedPs.legacyId} />}
            </div>
          </div>

          <div>
            <Text className="history-box__box-data" variant={TextVariant.BODY_SMALL}>
              <I18nMessage id={HistoryI18n.BOX} prefix={HISTORY_I18N_PREFIX} /> {box.legacyId}
              <span> · </span>
              <I18nDate dateStyle="medium" value={box.plannedFor} />
            </Text>
          </div>

          <div className="history-box__comment-container">
            {box.comment && (
              <Tooltip title={box.comment}>
                <Text className="history-box__comment-text" variant={TextVariant.BODY_SMALL}>
                  <span className="history-box__comment-title">{orderCommentLabel}: </span> {box.comment}
                </Text>
              </Tooltip>
            )}

            {box.checkoutComment && (
              <Tooltip title={box.checkoutComment}>
                <Text className="history-box__comment-text" variant={TextVariant.BODY_SMALL}>
                  <span className="history-box__comment-title">{checkoutCommentLabel}: </span> {box.checkoutComment}
                </Text>
              </Tooltip>
            )}

            {box.customerServiceComment && (
              <Tooltip title={box.customerServiceComment}>
                <Text className="history-box__comment-text" variant={TextVariant.BODY_SMALL}>
                  <span className="history-box__comment-title">{customerServiceCommentLabel}: </span>
                  {box.customerServiceComment}
                </Text>
              </Tooltip>
            )}
          </div>
        </section>
      </Card>
    </article>
  );
};

export { HistoryBox };
