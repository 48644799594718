import { FC } from "react";
import classNames from "classnames";
import Tooltip from "../../../../../shared/ui/uiKit/atoms/tooltip/Tooltip";
import {
  FeedbackItemProvider,
  FeedbackItems,
} from "../../../../views/historyReturnQuestions/behaviors/useFeedbackItem";
import { FeedbackIconItem } from "../../../../views/historyReturnQuestions/components/feedbackIconItem/FeedbackIconItem";
import { Feedbacks } from "../../../../views/historyReturnQuestions/Feedbacks";
import { HistoryProductVariantFeedbackTooltip } from "./HistoryProductVariantFeedbackTooltip";
import { HostDefaultFeedbackItem } from "../../../../views/historyReturnQuestions/components/hostDefaultFeedbackItem/HostDefaultFeedbackItem";
import { HistoryProductVariantFeedbackProjection } from "../../../../../core/projection/historyBox/historyBox";
import { FeedbackType } from "../../../../../core/projection/historyBox/historyBox.constants";
import "./history-product-variant-feedback.css";

const feedbackItems: FeedbackItems = {
  [FeedbackType.HOST_DEFAULT]: HostDefaultFeedbackItem,
  [FeedbackType.HOST_TEXTAREA]: HostDefaultFeedbackItem,
  [FeedbackType.HOST_SELECT]: HostDefaultFeedbackItem,
  [FeedbackType.HOST_STACK]: HostDefaultFeedbackItem,
  [FeedbackType.TEXTAREA]: FeedbackIconItem,
  [FeedbackType.OPTION]: FeedbackIconItem,
};

interface HistoryProductVariantFeedbackProps {
  readonly feedbacks: HistoryProductVariantFeedbackProjection[];
  readonly className?: string;
}

const HistoryProductVariantFeedback: FC<HistoryProductVariantFeedbackProps> = ({ feedbacks, className }) => (
  <Tooltip title={<HistoryProductVariantFeedbackTooltip feedbacks={feedbacks} />}>
    <ul className={classNames("history-product-variant-feedback", className)}>
      <FeedbackItemProvider feedbackItems={feedbackItems}>
        <Feedbacks feedbacks={feedbacks} />
      </FeedbackItemProvider>
    </ul>
  </Tooltip>
);

export { HistoryProductVariantFeedback };
