import Price from "../../../projection/price/model/Price";
import BoxPreviewStatus from "../../../domain/boxPreview/model/BoxPreviewStatus";
import Sizing from "../../../domain/sizing/model/Sizing";
import Media from "../../../projection/media/model/Media";
import Size from "../../../projection/size/model/Size";
import {
  HistoryBoxProjection,
  HistoryProductVariantProjection,
  HistoryProductVariantFeedbackProjection,
} from "../../../projection/historyBox/historyBox";

type HistoryProductVariantDto = {
  readonly productId: string;
  readonly productVariantId: string;
  readonly media: Media[];
  readonly kept: boolean;
  readonly feedback: HistoryProductVariantFeedbackProjection[];
  readonly size: Size;
  readonly sizeChange?: Size;
  readonly price: Price;
  readonly sizing: Sizing;
};

type HistoryBoxDto = {
  readonly boxId: string;
  readonly legacyId: string;
  readonly datePlanned: string;
  readonly customerServiceComment: string | null;
  readonly checkoutComment: string;
  readonly comment: string;
  readonly assignedPs: {
    readonly legacyId: number;
  };
  readonly pricing: {
    readonly purchased: Price;
    readonly total: Price;
  };
  readonly items: HistoryProductVariantDto[];
  readonly preview: {
    readonly id: string;
    readonly status: BoxPreviewStatus;
  };
};

const toHistoryProductVariant = ({
  productId,
  productVariantId,
  media,
  kept,
  feedback,
  size,
  sizeChange,
  price,
  sizing,
}: HistoryProductVariantDto): HistoryProductVariantProjection => ({
  productId,
  productVariantId,
  media: media as Media[],
  kept,
  feedback,
  size,
  sizeChange,
  price,
  sizing,
});

const toHistoryBoxProjection = ({
  boxId,
  legacyId,
  datePlanned,
  customerServiceComment,
  checkoutComment,
  comment,
  assignedPs,
  pricing,
  items,
  preview,
}: HistoryBoxDto): HistoryBoxProjection => ({
  id: boxId,
  legacyId,
  plannedFor: new Date(datePlanned),
  customerServiceComment,
  checkoutComment,
  comment,
  assignedPs: assignedPs ? { legacyId: assignedPs.legacyId } : null,
  pricing,
  items: items.map(toHistoryProductVariant),
  preview,
});

export type { HistoryBoxDto };
export { toHistoryBoxProjection };
